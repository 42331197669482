import React from "react";
import "fontsource-roboto";
import {
  Typography,
  Radio,
  RadioGroup,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  Card: {
    display: "flex",
    border: "1px solid rgb(103, 158, 255)",
    margin: theme.spacing(1),
  },
}));

export default function RadioButtonsGroup(props) {
  const classes = useStyles();

  return (
    <Box mb={1}>
      <Card b={10} variant="outlined" className={classes.Card}>
        <CardContent>
          <Typography variant="body2">{props.question}</Typography>
          <Typography component="div" variant="body2">
            <Box fontStyle="italic" mb={1}>
              {props.question2}
            </Box>
          </Typography>
          <Divider variant="middle" />
          <RadioGroup
            aria-label="chose"
            name={props.name}
            key={props.index}
            value={props.value}
            onChange={props.onChange}
            row
          >
            <FormControlLabel
              value={props.bobot}
              control={<Radio name={"rd-" + props.name} color="primary" />}
              label={<Typography variant="body2">Ya / Yes</Typography>}
              labelPlacement="end"
            />
            <FormControlLabel
              value={0}
              control={<Radio name={"rd-" + props.name} color="primary" />}
              label={<Typography variant="body2">Tidak / No</Typography>}
              labelPlacement="end"
            />
          </RadioGroup>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </Box>
  );
}
