import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Dropdown(props) {
  const classes = useStyles();
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel required>Company</InputLabel>
        <Select
          labelId={props.name}
          id={props.id}
          onChange={props.onChange}
          label="Company"
          required
          value={props.value}
        >
          {props.data.length > 0 &&
            props.data.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.cmpName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
