import React, { useState, useEffect } from "react";
import clsx from "clsx";
import "./styles.css";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Textbox from "./components/Textbox";
import Dropdown from "./components/Dropdown";
import QuestionCard from "./components/QuestionCard";
import Icon from "@material-ui/core/Icon";
import {
  Collapse,
  Button,
  Box,
  Typography,
  AppBar,
  Toolbar,
  useScrollTrigger,
  Paper,
  Grid,
  CircularProgress
} from "@material-ui/core";
import PropTypes from "prop-types";
import { loadCSS } from "fg-loadcss";
import { blue, indigo } from "@material-ui/core/colors";
import ReCAPTCHA from "react-google-recaptcha";
import env from "./env";
import request from "./utils/request";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function dates(date) {
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "Mei",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dateo = new Date(date).toISOString().substr(0, 19).replace("T", " ");
  const dater = new Date(dateo.split(" ")[0]);
  const datei =
    monthList[dater.getMonth()] +
    " " +
    dater.getDate().toString() +
    ", " +
    dater.getFullYear().toString() +
    " " +
    dateo.split(" ")[1];
  return datei;
}

const Assessment = (props) => {
  let ans = [];
  const [questions, setQuestions] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const res = await request({ url: "/assessment" });
        setQuestions(res.data);
      } catch (error) {}
    };

    const getCompanies = async () => {
      try {
        const res = await request({ url: "/company" });
        if (res.data.length) {
          const companies = res.data.map(({ name, code }) => ({
            cmpName: name,
            value: code,
          }));
          setCompanies(companies);
        }
      } catch (error) {}
    };

    const getBackgrounds = async () => {
      try {
        const res = await request({
          url: "/genfunc/LookupMasterParam",
          params: { paramcode: "SKRBGWEB" },
        });
        if (res.data.length) {
          setBackgrounds(res.data[0].mpa_descriptions);
        }
      } catch (error) {}
    };

    getBackgrounds();
    getQuestions();
    getCompanies();
  }, []);

  questions.forEach(function (e) {
    ans.push({ questionId: e.quest_questionid, questionValue: null });
  });

  const [checked, setChecked] = useState(true);
  const [checkedq, setCheckedq] = useState(false);
  const [checkedr, setCheckedr] = useState(false);
  const [fname, setfName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [capcay, setCapcay] = useState(null);
  const [backgrounds, setBackgrounds] = useState("");
  const [companies, setCompanies] = useState([]);

  const [assres, setAssres] = useState({
    risk: "",
    date: "",
    message: "",
    color: "",
    icon: "",
  });

  const [answer, setAnswer] = useState(ans);

  const handleCapcay = (e) => {
    console.log("Captcha value:", e);
    setCapcay(e);
  };
  const handleChangeName = (e) => {
    setfName(e.target.value);
  };
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const handleChangeQuestion = (i, e) => {
    const _answer = [...answer];
    const _questions = [...questions];
    _answer[i].questionValue = e.target.value;
    _questions[i].quest_assessmentid = parseInt(e.target.value);
    setQuestions(_questions);
    setAnswer(_answer);
  };

  const handleChange = () => {
    if (fname !== "" && phone !== "" && company !== "") {
      setChecked((prev) => !prev);
      setCheckedq((prev) => !prev);
      setAnswer(ans);
    } else {
      alert("Field must be filled");
    }
  };
  const handleChangeq = () => {
    setCheckedq((prev) => !prev);
    setCheckedr((prev) => !prev);
  };
  const handleSubmit = () => {
    var submt = false;
    for (var an of answer) {
      if (an.questionValue === null) {
        submt = false;
        break;
      }
      submt = true;
    }

    if (submt === true) {
      if (capcay === null) {
        alert("Captcha must be filled out.");
      } else {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization:
              "Bearer KHfsdl842_&87ddfpPJdlfn7_@kfdJDUIW89T637klfdlUBDF8679gf787HKJ_jcKjfuBF290",
          },
          body: JSON.stringify({
            userId: "",
            guestName: fname,
            guestCompId: company,
            questionsList: answer,
          }),
        };

        fetch(`${env.HCMS_API}/assessment/submit`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            let datev = new Date(data.data[0].skr_createddate);
            const datei = dates(data.data[0].skr_createddate);
            const dateie = dates(datev.setHours(datev.getHours() + 4));

            if (data.success === true) {
              if (data.data[0].skr_totalscore >= 5) {
                alert("aa");
                datev.setHours(datev.getHours() - 4);
                setAssres({
                  risk: "High Risk",
                  date: datei,
                  dateExp: dates(datev.setDate(datev.getDate() + 14)),
                  message: "You are not allowed to work in the office.",
                  message2: "Please consult to health facilities.",
                  message3: '"Anda tidak diperkenankan bekerja di kantor.',
                  message4: 'Silahkan konsultasi ke fasilitas kesehatan."',
                  color: "red",
                  icon: "fa fa-times",
                });
              } else if (
                data.data[0].skr_totalscore <= 5 &&
                data.data[0].skr_totalscore >= 1
              ) {
                alert("ccc");
                setAssres({
                  risk: "Medium Risk",
                  date: datei,
                  dateExp: dateie,
                  message: "You are allowed to work in the office.",
                  message3: '"Anda diperkenankan bekerja di kantor"',
                  message2: null,
                  message4: null,
                  color: "yellow",
                  icon: "fa fa-exclamation-triangle",
                });
              } else if (data.data[0].skr_totalscore === 0) {
                setAssres({
                  risk: "Low Risk",
                  date: datei,
                  dateExp: dateie,
                  message: "You are allowed to work in the office.",
                  message3: '"Anda diperkenankan bekerja di kantor"',
                  message2: null,
                  message4: null,
                  color: "green",
                  icon: "fa fa-check-square",
                });
              }
            }

            handleChangeq();
          });
      }
    } else {
      alert("You must answer all questions.");
    }
  };

  const theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: indigo,
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar style={{ backgroundColor: "#0676b8" }}>
          <Toolbar>
            <Typography variant="h6">Covid-19 Self Assessment</Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <Collapse in={checked}>
          <Textbox
            style={{ backgroundColor: "#0badaf" }}
            onChange={handleChangeName}
            id="fname"
            label="Full Name"
            auto="name"
            autoFocus={true}
          />
          <Textbox
            onChange={handleChangePhone}
            id="phone"
            label="Phone"
            auto="phone"
          />
          <Box mt={2}>
            <Dropdown
              onChange={handleChangeCompany}
              name="company"
              value={company}
              id="company"
              data={companies}
              mb={2}
            />
          </Box>

          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleChange}>
              Continue
            </Button>
          </Box>
        </Collapse>
        <Collapse in={checkedq}>
          <Box mt={1} style={{ marginTop: 20 }}>
            <Box mb={2} mt={2} p={1}>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: backgrounds }}
              />
            </Box>
            {questions.map((question, index) => (
              <QuestionCard
                key={index}
                question={question.quest_question}
                question2={question.quest_question2}
                name={"question"}
                bobot={question.quest_bobot}
                onChange={(e) => handleChangeQuestion(index, e)}
                value={question.quest_assessmentid}
              />
            ))}
            <Typography component="div" variant="body2"></Typography>
            <Box mt={2} mb={5} p={1}>
              <Box mb={2}>
                <ReCAPTCHA
                  sitekey="6LfJBLgZAAAAABvYG6f6eQXfJrqASQK8VjJoIM6Z"
                  onChange={handleCapcay}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Collapse>
        <Collapse in={checkedr}>
          <Box pt={5}>
            <Paper
              elevation={3}
              className={clsx(classes.button, {
                [classes.buttonRed]: assres.color === "red",
                [classes.buttonYellow]: assres.color === "yellow",
                [classes.buttonGreen]: assres.color === "green",
              })}
            >
              <Box pt={5}>
                <Typography
                  variant="h3"
                  component="h3"
                  className={classes.datename}
                >
                  {fname}
                </Typography>

                <Typography className={classes.typho}>{assres.risk}</Typography>
                <Typography className={classes.typho}>
                  <Icon className={assres.icon} style={{ color: "white" }} />
                </Typography>
                <Typography className={classes.dateAss}>
                  Assessment Date :
                </Typography>
                <Typography className={classes.dateAss}>
                  {assres.date} (WIB)
                </Typography>
                <Typography className={classes.dateAss}>
                  Expired Date :
                </Typography>
                <Typography className={classes.dateAss}>
                  {assres.dateExp} (WIB)
                </Typography>
              </Box>
              <Box pt={5}>
                <Typography variant="subtitle1" className={classes.date}>
                  {assres.message}
                </Typography>
                <Typography variant="subtitle1" className={classes.date}>
                  {assres.message2}
                </Typography>
                <Box pt={1}></Box>
                <Typography variant="subtitle1" className={classes.date}>
                  {assres.message3}
                </Typography>
                <Typography variant="subtitle1" className={classes.date}>
                  {assres.message4}
                </Typography>
              </Box>
              <Box pt={5}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    textAlign: "center", // this does the magic
                  }}
                >
                  <Button
                    className={classes.buttonRes}
                    onClick={() => window.location.reload(false)}
                  >
                    Done
                  </Button>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Collapse>
      </ThemeProvider>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    background: "#eb131a",
    borderRadius: 3,
    border: 0,
    margin: theme.spacing(2),
    spacing: 0,
    direction: "column",
    alignItems: "center",
    justify: "center",
    minHeight: "680px",

    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  buttonYellow: {
    background: "#ffab00",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  },
  buttonGreen: {
    background: "#4FD44C",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  },
  buttonRed: {
    background: "#EE4957",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  },
  typho: {
    fontSize: 30,
    fontWeight: "bold",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  date: {
    fontSize: 14,
    fontWeight: 500,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dateAss: {
    fontSize: 16,

    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  datename: {
    fontSize: 25,

    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonRes: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    widht: "90%",
    color: "white",
    border: "solid",
    borderWidth: "1px",
    minWidth: "300PX",
  },
  buttonPrimary: {},
}));

// export default Assessment;

const Admin = () => {
  useEffect(() => {
    setTimeout(() => {
      window.open('https://covid-19-admin.mst.co.id/', '_self')
    }, 1000)
  })

  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 20}}>
      <CircularProgress size={20} />
      &nbsp;
      <h3>Loading...</h3>
    </div>
  )
}

export default function App(){
  return (
    <Router>
      <Switch>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/">
          <Assessment />
        </Route>
      </Switch>
    </Router>
  );
}
