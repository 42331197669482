import env from "../env";

const methods = ["PUT", "POST"];
export default async function request({
  url = "/",
  method = "",
  params = {},
  body = {},
}) {
  try {
    let endpoint = env.HCMS_API + url;

    const config = {
      headers: {
        authorization:
          "Bearer KHfsdl842_&87ddfpPJdlfn7_@kfdJDUIW89T637klfdlUBDF8679gf787HKJ_jcKjfuBF290",
      },
    };

    if (methods.find((i) => i === method) && Object.entries(body).length) {
      config.body = JSON.stringify(params);
    }

    if (params) {
      endpoint += "?" + new URLSearchParams(params).toString();
    }

    const res = await fetch(endpoint, config);
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}
