import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Textbox(props) {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id={props.id}
      label={props.label}
      name={props.id}
      autoComplete={props.auto}
      autoFocus={props.autoFocus}
      helperText={props.helper}
      onChange={props.onChange}
    />
  );
}
